<template>
    <div>
        <h4 class="title is-4 has-text-primary">{{meta.title}}</h4>
        <p class="subtitle" v-html="meta.description"></p>
        <iframe :src="`https://www.youtube.com/embed/${meta.video}`" frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen></iframe>
    </div>
</template>
<style>
    iframe {
        width: 100%;
        min-height: 300px;
    }
</style>
<script>
    export default {
        props: {
            meta: {
                type: Object,
                required: true
            }
        }
    }
</script>