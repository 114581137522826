<template>
    <div>
        <section class="hero is-medium is-dark has-background">
            <img :src="`https://swissdominoeffect.com/img/headers/${header_image}`" class="is-transparent hero-background" />
            <div class="hero-body">
                <div class="container">
                    <h1 class="title is-1 is-size-2-mobile">
                        Projekte & Events
                    </h1>

                </div>
            </div>
        </section>
        <section class="section">
            <div class="container">
                <div class="content is-medium">
                    <p class="subtitle is-4">
                        Neben eigenen Projekten planen und realisieren wir regelmässig Workshops, PR-Events und weitere
                        Veranstaltungen für Firmen und Privatpersonen. Machen Sie sich einen Eindruck von unseren
                        bisherigen Events wie zum Beispiel dem Auftritt am Eurovision Song Contest oder unseren
                        Weltrekorden in Häggenschwil.
                    </p>
                    <div class="columns is-multiline">
                        <VideoView v-for="(project, index) in projects" :key="index" :meta="project"
                            class="column is-6" />
                        <div class="column is-6">
                            <div class="notification is-primary">
                                <h3 class="title is-3">Noch mehr Projekte ansehen?</h3>
                                <p>Weitere Videos können auf unserem Youtube-Kanal angesehen werden.</p>
                                <a class="button is-white is-medium" target="_blank"
                                    href="https://youtube.com/swissdominoeffect">Youtube-Kanal</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>
<script>
    import VideoView from '@/components/VideoView'
    export default {
        metaInfo: {
            title: 'Projekte & Events mit Dominosteinen',
            meta: [{
                    name: 'description',
                    content: 'Realisieren Sie ihr Projekt mit tausenden, bunten Dominosteinen. Gerne begleiten wir Sie von der Idee bis zum letzten aufgestellten Dominostein. Schwirrt Ihnen eine Idee im Kopf herum? Melden Sie sich jetzt online über unsere Website. Gerne beraten wir Sie dazu.'
                },
                {
                    name: 'keywords',
                    content: 'Vielfältige Projekte, Kreative Umsetzung, Motto, Kettenreaktion, Dominosteine, Schweiz'
                }
            ],
        },
        data: function () {
            return {
                header_image: 'projekte_events_domino_banner.jpg',
                projects: [{
                        title: 'Zürich Game Show',
                        description: 'Auf Einladung der Zürich Game Show durften wir mit unseren Dominosteinen ein Projekt mit mehr als 15\'000 Dominosteinen aufbauen.',
                        video: 'AgFb3YrtalA'
                    },
                    {
                        title: 'Fantasy Basel',
                        description: 'Auf Einladung der Fantasy Basel 2018 - The Swiss Comic Con durften wir mit unseren Dominosteinen ein Projekt mit mehr als 20\'000 Dominosteinen aufbauen.',
                        video: 'KjoknOnOyOg'
                    },
                    {
                        title: 'dot.swiss Kampagne',
                        description: 'Zusammen mit der PR-Agentur <a href="https://www.farner.ch/">Farner Consulting AG</a> durften wir 2016 zum Finale der Werbe-Kampagne dot.swiss eine Kettenreaktion mit 60\'000 Dominosteinen aufbauen.',
                        video: 'r6pPKzRtyb4'
                    },
                    {
                        title: 'Centre Bahnhof in Biel/Bienne',
                        description: '2016 feierte das Centre Bahnhof in Biel mit 20\'000 Dominosteinen sein zehn jähriges Jubiläum.',
                        video: 'ENiL8udnD0o'
                    },
                    {
                        title: 'Mythencenter Schwyz',
                        description: 'Im Frühjahr 2016 wurden anlässlich des Josefstages im Mythencenter 30\'000 Dominosteine umgestossen.',
                        video: 'y-Bq6G2Q2Q8'
                    },
                    {
                        title: 'Comics in 100\'000 Dominosteinen',
                        description: 'Im Sommer 2015 fand unser bislang grösstes, selbstorganisiertes Event statt. Mit 100\'000 Dominosteinen ist dies die bisher längste von uns erbaute Kettenreaktion.',
                        video: 'gITkbyaxYAY'
                    },
                    {
                        title: 'Dominokettenreaktion mit Matratzen',
                        description: 'Im Jahre 2014 konnten wir in Zusammenarbeit mit der Gemeinde Häggenschwil (SG) ein Weltrekordversuch mit mehr als 1\'000 Matratzen realisieren.',
                        video: '-ZyGse1S2us'
                    },
                    {
                        title: 'Die grössten Schweizer Talente',
                        description: 'Im Frühjahr 2015 durften wir in der Sendung "Die grössten Schweizer Talente" auf Anfrage von SRF auftreten.',
                        video: 'W7DqIJbc4cc'
                    },
                    {
                        title: 'Weltrekord Buchdomino an der Hochschule St. Gallen',
                        description: 'Im Herbst 2014 bauten wir anlässlich der Museumsnacht in St.Gallen eine Kettenreaktion von über 5000 Büchern auf.',
                        video: 'UVrg359IpVo'
                    },
                    {
                        title: 'Weltrekord 3D Pyramide in Häggenschwil',
                        description: 'Im Sommer 2014 gelang uns der Weltrekord der grössten 3D Pyramide in Häggenschwil.',
                        video: 'Fqb-TjYgvq4'
                    },
                    {
                        title: 'Eurovision Song Contest',
                        description: '2014 durften wir für den Künstler Sebalter die schweizer Postcard aus Dominosteinen aufbauen.',
                        video: 'T1rbUdIvh6c'
                    },
                ]
            }
        },
        components: {
            VideoView
        }
    }
</script>